export const ENV = 'DEV';

export const DEV_FEATURES = {
    PHONE_NUMBER_PROD_VALIDATION: false,
    CAPTCHA: false,
    FA_WS_STREAMING : false
};

export const PROD_FEATURES = {
    PHONE_NUMBER_PROD_VALIDATION: false,
    CAPTCHA: true,
    FA_WS_STREAMING : false
};

export const baseUrl = ENV === 'PROD' ?
    "https://rvac8zf2jb.execute-api.us-east-1.amazonaws.com" :
    "https://ud13av7oi1.execute-api.us-east-1.amazonaws.com";

export const stripeAPIKey = ENV === 'PROD' ?
    'pk_live_DqY8lEqvcJnTKVO8NnykWLas00KcNAbgAP' :
    'pk_test_j2y7JFdZyeV9u73cIOJK40wp00qztV3PQB';

export const stripeUrl = ENV === 'PROD' ?
    'https://c8ag1aavua.execute-api.us-east-1.amazonaws.com' :
    'https://637x0bqex8.execute-api.us-east-1.amazonaws.com';

export const contactUsBaseUrl = 'https://h2nj5zomzj.execute-api.us-east-1.amazonaws.com';

export const SMS_LIMIT = 1;
export const SMS_ABSOLUTE_LIMIT = 10;
export const SYMBOLS_LIMIT = 10;
export const SYMBOLS_ABSOLUTE_LIMIT = 300;

export default {
    apiGateway: {
        REGION: "us-east-1",
        URL: ENV === "PROD" ?
            "https://rvac8zf2jb.execute-api.us-east-1.amazonaws.com/v1" :
            "https://ud13av7oi1.execute-api.us-east-1.amazonaws.com/v1"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_B5CgBEapF",
        APP_CLIENT_ID: "37i8v2t02b8lb88u7cktc5d06u"
    }
};

export const DataSetsProduct = 'https://us-east-1.console.aws.amazon.com/dataexchange/home?region=us-east-1#/products/prodview-7th3w5f27hhau';
export const DataSetsSampleProduct = 'https://us-east-1.console.aws.amazon.com/dataexchange/home?region=us-east-1#/products/prodview-dogckezg2oiqq';
