import React, { Component } from "react";
import "./Home.css";
import Tooltip from '../Tooltip';
import SignupForm from './Signup';
import SigninForm from './Login';
import AlexaRedirectButton from './AlexaRedirectButton';
import ResetPasswordForm from './ResetPassword';
import FlashAlertStreaming from '../FlashAlertStreaming';
import { isFeatureActive, getCookie, setCookie } from '../../utils/common';
import { Helmet } from "react-helmet";
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class Home extends Component {

    getCurrentForm() {
        const {
            history, showPopupMessage, getUser, header, switchSignTarget, cognitoAuthURL, handleUserAttrs
        } = this.props;
        const { signTarget } = header;
        return <div style= {{ color: "white" }}>Not available</div>
        // remove sign-in and sign-up forms
        if (cognitoAuthURL) return <AlexaRedirectButton cognitoAuthURL={cognitoAuthURL} />;
        switch (signTarget) {
            case 'Sign In':
                return <SigninForm history={history} />;
            case 'Sign Up':
                return <SignupForm
                            history={history}
                            showPopupMessage={showPopupMessage}
                            handleUserAttrs={handleUserAttrs}
                            getUser={getUser} />;
            case 'Reset Password':
                return <ResetPasswordForm
                            showPopupMessage={showPopupMessage}
                            switchSignTarget={switchSignTarget} />;
        }
    }

    handleAfterSignUpAction(e, action) {
        e.preventDefault();
        window.open('/products');
        // const { user, switchSignTarget, handleAlertsStateUpdate, history } = this.props;
        // const { isAuthenticated } = user;
        // console.log("handleAfterSignUpAction test isAuthenticated", isAuthenticated)
        // console.log("handleAfterSignUpAction test action", action)
        // if (!isAuthenticated){
        //     switchSignTarget('Sign Up');
        // };
       
        // if (action) {
        //     setCookie('afterSignUpAction', action, 1)
        //     // handleAlertsStateUpdate({'afterSignUpAction': action})
        // };

        // if (isAuthenticated) {
        //     if (action === 'Data Sets') {
        //         window.open('');
        //     }
        //     window.open("/alerts");
            
           
        // };
    }

    render() {
        const { user, cognitoAuthURL } = this.props;
        const { isAuthenticated } = user;
        return (
            <div className="home-container">
                <div className="home-top-container">
                    <div className="home-top-text-half">
                        <h1>Real-time Streaming Structured News, historical data and alerts on publicly-traded equities</h1>
                        <h3>Subscribe to news covering 13,000 stocks and our service will keep you updated in real time</h3>
                        { cognitoAuthURL && <AlexaRedirectButton style={{ marginTop: 20 }} cognitoAuthURL={cognitoAuthURL} /> }
                        <div className="home-top-tooltips-container">
                            <span>EMAIL ALERTS</span>
                            <span>API FEEDS</span>
                            <span>DATA SETS</span>
                            {/* <Tooltip
                                title="Create an account"
                                message="Select SMS alerts"
                                spanText="SMS"
                            /> */}
                            {/* <Tooltip
                                title="Create an account"
                                message="Select Email alerts"
                                spanText="EMAIL ALERTS"
                            /> */}
                            {/* <Tooltip
                                title="Go to Google device and say"
                                message="‘Hey Google, Ask Flash Alert’"
                                spanText="GOOGLE HOME"
                            /> */}
                            {/* <Tooltip
                                title="Go to Alexa device and say"
                                message="‘Alexa, Flash Alert’"
                                spanText="AMAZON ALEXA"
                            /> */}
                            {/* <Tooltip
                                title="Use 'Contact Us'"
                                message="Specify subject: Web socket streaming API key"
                                spanText="API FEEDS"
                            />
                             <Tooltip
                                title="Use 'Contact Us'"
                                message="Specify subject: Data sets access"
                                spanText="DATA SETS"
                            /> */}
                        </div>
                        <h2>NYSE • NASDAQ • AMEX • OTC</h2>
                        <h4>Real time with minimal latency</h4>
                        <div className="home-top-links-container">
                            <h3 onClick={(e) => this.handleAfterSignUpAction(e, '')}><AnchorLink href='#signup-form'>Receive</AnchorLink> all news for your portfolio by email.</h3>
                            <h3 onClick={(e) => this.handleAfterSignUpAction(e, 'Streaming Structured News')}><AnchorLink href='#signup-form'>Streaming Structured News</AnchorLink> via Websockets with minimal latency.</h3>
                            <h3 onClick={(e) => this.handleAfterSignUpAction(e, 'Data Sets')}><AnchorLink href='#signup-form'>Multi-year historical data sets</AnchorLink> of PR news and SEC data.</h3>
                        </div>
                    </div>
                    {
                        !isAuthenticated &&
                        <div className="home-top-form-half" id="signup-form">
                            {this.getCurrentForm()}
                        </div>
                    }
                </div>
                <div className="home-middle-container">
                    {/* <div className="home-middle-main-img">
                        <img src="images/imageMainPicture.png" />
                        <img src="images/MainImage-Tablet.png" />
                    </div> */}
                    {isFeatureActive('FA_WS_STREAMING') &&
                    <FlashAlertStreaming />
                    }

                    <div className="home-middle-under-main-img-block">
                        <div>
                            <div className="home-middle-img-container">
                                <img width={66} height={54} src="images/iconUpgradesDowngrades.png" />
                            </div>
                            <h2>Upgrades & Downgrades</h2>
                            <h3>Sourced from Briefing.com</h3>
                        </div>
                        <div>
                            <div className="home-middle-img-container">
                                <img width={48} height={66} src="images/iconSECfilling.png" />
                            </div>
                            <h2>SEC Filling</h2>
                            <h3>Comes directly from SEC database</h3>
                        </div>
                        <div>
                            <div>
                                <div className="home-middle-img-container">
                                    <img width={46} height={66} src="images/iconPrReleases.png" />
                                </div>
                                <h2>PR releases</h2>
                                <h3>Real-time feeds from Business Wire, Globe Newswire, PR Newswire, and Acesswire</h3>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="home-middle-img-container">
                                    <img width={66} height={66} src="images/iconCompanyNews.png" />
                                </div>
                                <h2>Company News</h2>
                                <h3>SeekingAlpha and others</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home-bottom-container">
                    <h1>Your personal low-latency service</h1>
                    <div className="home-bottom-for-block">
                        <div>
                            <div className="home-bottom-img-container">
                                <img width={50} height={46} src="images/iconForTraders.png" />
                            </div>
                            <h2>For traders</h2>
                            <h3>
                                Who want to algorithmically ingest structured breaking market news to buy and sell securities before the news is published on major websites
                            </h3>
                        </div>
                        <div>
                            <div className="home-bottom-img-container">
                                <img width={62} height={62} src="images/iconForInvestors.png" />
                            </div>
                            <h2>For investors</h2>
                            <h3>
                                Who want to minimize risk to their portfolio by getting SEC and PR data in immediately actionable format
                            </h3>
                        </div>
                        <div>
                            <div className="home-bottom-img-container">
                                <img width={61} height={60} src="images/iconForReserch.png" />
                            </div>
                            <h2>For research analysts</h2>
                            <h3>
                                Who want to backtest trading strategy, data mine patterns, or discover historical correlations in new on equities
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
